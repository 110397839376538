<template>
    <div>
        <ShStepTwo
            kind="offer"
            :kindtext="$lang.pages.compartirOfertaCrear.of_the_offer"
            :info="options"
            :validation-status="validationStatus"
            :loading="loading"
            @makePost="makePost"
            @setValidations="setValidations"
        />
    </div>
</template>

<script lang="ts" setup>
import { format } from 'date-fns'
import encodeUrl from 'encodeurl'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import { parseImages } from '~/util/image-functions'
import type { Api } from '~~/global'

const props = defineProps({
    options: {
        type: Object,
        default() {
            return {
                url: '',
                code: '',
            }
        },
    },
})

definePageMeta({ middleware: ['auth-required'] })
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const loading = ref(false)

const validationStatus = reactive({
    url: false as boolean | string,
    title: false as boolean | string,
    description: false as boolean | string,
    discount_value: false as boolean | string,
    category_id: false as boolean | string,
    country_id: false as boolean | string,
    new_price: false as boolean | string,
})

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()
const { $lang } = useNuxtApp()

const title = $lang.pages.compartirOfertaCrear.title_seo
const description = $lang.pages.compartirOfertaCrear.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const scrollToComponent = useScrollToComponent()
const RootStore = useRootStore()
const Router = useRouter()

const AuthStore = useAuthStore()

const setValidations = (
    name: 'url' | 'title' | 'description' | 'discount_value' | 'category_id' | 'country_id',
    status: boolean | string = false,
) => {
    validationStatus[name] = status
}

const makePost = async (data: any) => {
    loading.value = true

    try {
        const { images, featured } = await parseImages(data)

        console.log(images, featured)

        // Manipulamos la fecha inicial
        data.start_at = data.end_at = ''
        if (data.datePicker.start.date) {
            data.start_at = format(data.datePicker.start.date, 'yyyy-MM-dd')
            data.start_at += 'T' + data.datePicker.start.time + ':00'
        }
        // Manipulamos la fecha de finalizacion
        if (data.datePicker.end.date) {
            data.end_at = format(data.datePicker.end.date, 'yyyy-MM-dd')
            data.end_at += 'T' + data.datePicker.end.time + ':00'
        }

        // Creamos el nuevo formData
        const bodyFormData = new FormData()

        if (featured) bodyFormData.append('featured', featured)

        for (let i = 0; i < images.length; i++) {
            bodyFormData.append('images[]', images[i])
        }

        if (data.categoryId) bodyFormData.append('category_id', data.categoryId)
        if (data.countryId) bodyFormData.append('country_id', data.countryId)
        if (data.url) bodyFormData.append('url', data.url)
        if (data.offer_details.price_offer) bodyFormData.append('new_price', data.offer_details.price_offer)
        if (data.offer_details.regular_price)
            bodyFormData.append('old_price', data.offer_details.regular_price)
        if (data.offer_details.ship_costs)
            bodyFormData.append('shipping_price', data.offer_details.ship_costs)
        if (data.offer_details.freeship) bodyFormData.append('shipping_free', data.offer_details.freeship)
        if (data.code) bodyFormData.append('code', data.code)
        if (data.title) bodyFormData.append('title', data.title)
        if (data.description) bodyFormData.append('description', data.description)
        if (data.start_at) bodyFormData.append('start_at', data.start_at)
        if (data.end_at) bodyFormData.append('expired_at', data.end_at)
        if (data.isLocal) bodyFormData.append('is_local', data.isLocal)

        const response = await $fetch<
            Api.Responses.Actions.SuccessCoupon | Api.Responses.Actions.FailedCoupon
        >(endpoints.create.offers.path, {
            method: 'POST',
            body: bodyFormData,
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
        }).catch((e) => e.data)

        if (!response) {
            RootStore.setSiteNotification({
                duration: 5000,
                text: $lang.pages.compartirOfertaCrear.error_on_create,
                type: 'error',
            })
        } else if (response.feedback === 'params_validation_failed') {
            validationStatus.url = response.data.url?.[0] || false
            validationStatus.title = response.data.title?.[0] || false
            validationStatus.description = response.data.description?.[0] || false
            validationStatus.category_id = response.data.category_id?.[0] || false
            validationStatus.country_id = response.data.country_id?.[0] || false
            validationStatus.new_price = response.data.new_price?.[0] || false
        } else if (response.feedback === 'resource_created') {
            RootStore.setSiteNotification({
                duration: 5000,
                text: $lang.pages.compartirOfertaCrear.offer_success,
                type: 'success',
            })
            Router.push({
                path: `/compartir/finalizado`,
                query: {
                    variant: 'offer',
                    slug: response.data.offer.slug,
                    revision: 'false',
                    title: encodeUrl(response.data.offer.title),
                },
            })
        } else if (response.feedback === 'resource_needs_approval') {
            Router.push({
                path: `/compartir/finalizado`,
                query: {
                    variant: 'offer',
                    revision: 'true',
                    title: encodeUrl(response.data.offer.title),
                },
            })
        } else {
            console.log(response)
        }
    } catch (error) {
        console.log('Fail on share offer: ', { error })
    }

    loading.value = false
}
</script>
